import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PopUpProvider } from "../../components/Cards/Cards.jsx";
import {
  resumeEntryOrders,
  resumeMonthEntryOrders,
} from "../../store/financial/resumeEntryOrders/resumeEntryOrders.actions.js";

import ProviderResume from "../../components/Resume/ProviderResume/ProviderResume.jsx";
import {
  DivFinancial,
  TitleFinEntryOrders,
  SelectMonth,
  OptionMonth,
  DivOrgBtnFilter,
  BtnSearch,
  BtnCancel,
  DivOrgSelect,
  DivOrgTitle,
} from "./FinancialEntryOrdersStyle.js";

import FormatDatesFront from "../../utils/formatDateFront.mjs";
import { monthsInfo, yearsInfo } from "../../utils/infoMonths.js";
import { Close, Search } from "@styled-icons/material";

export default function FinancialEntryOrders() {
  const [infoEntryOrders, setInfoEntryOrders] = useState([]);
  const formatDates = new FormatDatesFront();
  const [financialResumeOrders, setFinancialResumeOrders] = useState([]);
  const [disableFilter, setDisableFilter] = useState(false);
  const [infoMonth, setInfoMonth] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [filterDateMonth, setFilterDateMonth] = useState(
    formatDates.getMonth()
  );
  const [filterDateYear, setFilterDateYear] = useState(formatDates.getYear());
  const dispatch = useDispatch();

  const getResumeProvider = async () => {
    const getEntryOrders = await dispatch(resumeEntryOrders());
  
    setInfoEntryOrders(getEntryOrders.payload);
    setFinancialResumeOrders(getEntryOrders.payload);
  };

  const getMonthFinancial = async (infoDate) => {
    const getMonthResume = await dispatch(resumeMonthEntryOrders(infoDate));
    setInfoMonth(getMonthResume.payload);
  };

  const sendFilterMonth = () => {
    if (filterDateMonth >= 0 && filterDateYear >= 0) {
      const formatDate = `${filterDateMonth}/${filterDateYear}`;
      getMonthFinancial(formatDate);
    }
  };

  useEffect(() => {
    getResumeProvider();
    sendFilterMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetFilter === true) {
      sendFilterMonth();
    }
    setResetFilter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilter]);

  useEffect(() => {
    if (infoEntryOrders?.entryOrders?.length === 0) {
      setDisableFilter(true);
    } else {
      setDisableFilter(false);
    }
  }, [infoEntryOrders]);

  return (
    <DivFinancial>
      <DivOrgTitle>
        <TitleFinEntryOrders>Compras com Fornecedores</TitleFinEntryOrders>
        <DivOrgSelect>
          <SelectMonth
            value={filterDateMonth}
            onChange={(e) => {
              setFilterDateMonth(e.target.value);
            }}
          >
            {monthsInfo.map((infoMonth, index) => {
              return (
                <OptionMonth key={index} value={infoMonth.value}>
                  {infoMonth.nameMonth}
                </OptionMonth>
              );
            })}
          </SelectMonth>
          <SelectMonth
            value={filterDateYear}
            onChange={(e) => setFilterDateYear(e.target.value)}
          >
            {yearsInfo.map((infoYear, index) => {
              return (
                <OptionMonth key={index} value={infoYear.value}>
                  {infoYear.value}
                </OptionMonth>
              );
            })}
          </SelectMonth>
        </DivOrgSelect>
        <DivOrgBtnFilter>
          <BtnSearch type="button" onClick={sendFilterMonth}>
            <Search />
          </BtnSearch>
          <BtnCancel
            type="button"
            onClick={() => {
              setResetFilter(true);
              setFilterDateMonth(formatDates.getMonth());
              setFilterDateYear(formatDates.getYear());
            }}
          >
            <Close />
          </BtnCancel>
        </DivOrgBtnFilter>
      </DivOrgTitle>
      <PopUpProvider
        financialResumeOrders={financialResumeOrders}
        infoMonth={infoMonth}
      />
      <TitleFinEntryOrders>Fornecedores</TitleFinEntryOrders>
      <ProviderResume
        infoEntryOrders={infoEntryOrders?.entryOrders}
        disableFilter={disableFilter}
      />
    </DivFinancial>
  );
}
