import React, { useEffect, useState } from "react";
import UpdateProduct from "../../Update/UpdateProduct/UpdateProduct";
import InfoProduct from "../../Info/InfoProduct/InfoProduct";
import DeleteProduct from "../../DeleteComponent/DeleteProduct/DeleteProduct";
import {
  Edit,
  Search,
  DeleteForever,
  Visibility,
  Close,
} from "@styled-icons/material";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  BtnView,
  TitleProduct,
  DivBtnEdit,
  DivBtnSearch,
  DivProduct,
  DivProductInfo,
  DivSearch,
  DivSearchProduct,
  DivTableSearch,
  DivInfo,
  NameInput,
  NameLabel,
  SpanCod,
  SpanName,
  DivOrgLoading,
  DivOrgCard,
  IdProduct,
  DivOrgId,
  DivBtnFilter,
  BtnCancel,
  CodInput,
} from "./SearchProductStyle";

import { ClipLoader } from "react-spinners";

export default function SearchProduct(props) {
  const productInfo = props.productsInfo;

  const [productPopUp, setProductPopUp] = useState(false);
  const [delProductOption, setDelProductOption] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedProductView, setSelectedProductView] = useState();
  const [filterCodProduct, setFilterCodProduct] = useState("");
  const [filterNameProduct, setFilterNameProduct] = useState("");
  const [filterInfoProduct, setFilterInfoProduct] = useState([]);

  const [productView, setProductView] = useState(false);
  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState();
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [dataProductsUpdate, setDataProductsUpdate] = useState([]);

  const createListProduct = (dataProduct) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoProduct(dataProduct);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const parseName = (oneName) => {
    const fullName = oneName;

    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName.join(" ");

    return result;
  };

  const filterProduct = () => {
    const filterList = productInfo
      .filter((product) =>
        filterCodProduct > 0 ? product.codProd === filterCodProduct : product
      )
      .filter((product) =>
        filterNameProduct.length > 0
          ? product.nameProduct.includes(filterNameProduct.toLocaleLowerCase())
          : product
      );

    setFilterInfoProduct(filterList);
  };

  useEffect(() => {
    createListProduct(productInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  useEffect(() => {
    setLoading(true);
    if (loadingProducts) {
      props.getListProducts();
    }
    setTimeout(() => {
      setLoadingProducts(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProducts]);

  return (
    <DivSearchProduct>
      <DivSearch>
        <TitleProduct>Consulta Produto</TitleProduct>
        <DivBtnFilter show={props.disableFilter}>
          <NameLabel>Nome</NameLabel>
          <NameInput
            value={filterNameProduct}
            onChange={(e) => setFilterNameProduct(e.target.value)}
          />
          <NameLabel>Codigo</NameLabel>
          <CodInput
            value={filterCodProduct}
            onValueChange={(values, sourceInfo) => {
              setFilterCodProduct(parseInt(values.value));
            }}
          />
          <DivBtnSearch>
            <BtnSearch type="button" onClick={filterProduct}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCodProduct("");
                setFilterNameProduct("");
                setFilterInfoProduct(productInfo);
              }}
            >
              <Close />
            </BtnCancel>
          </DivBtnSearch>
        </DivBtnFilter>
      </DivSearch>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoProduct.map((infoProduct, index) => {
            return (
              <DivProduct key={index}>
                <DivOrgCard>
                  <DivInfo>
                    <DivOrgId>
                      <IdProduct>{index + 1}</IdProduct>
                    </DivOrgId>
                    <DivProductInfo>
                      <SpanName>{parseName(infoProduct.nameProduct)}</SpanName>
                      <SpanCod>Codigo: {infoProduct.codProd}</SpanCod>
                    </DivProductInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setProductPopUp(!productPopUp);
                        setDataProductsUpdate(infoProduct);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {productPopUp &&
                      infoProduct.idProduct ===
                        dataProductsUpdate.idProduct && (
                        <UpdateProduct
                          setLoadingProducts={setLoadingProducts}
                          dataProductsUpdate={dataProductsUpdate}
                          productPopUp={productPopUp}
                          setProductPopUp={setProductPopUp}
                        />
                      )}

                    <BtnView
                      onClick={() => {
                        setProductView(!productView);
                        setSelectedProductView(infoProduct);
                        setDelProductOption(false);
                      }}
                    >
                      <Visibility />
                    </BtnView>

                    <BtnRemove
                      onClick={() => {
                        setDelProductOption(!delProductOption);
                        setSelectedProduct(infoProduct);
                        setProductView(false);
                      }}
                    >
                      <DeleteForever />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivOrgCard>
                {productView &&
                  infoProduct.idProduct === selectedProductView.idProduct && (
                    <InfoProduct
                      selectedProductView={selectedProductView}
                      productView={productView}
                      setProductView={setProductView}
                    />
                  )}
                {delProductOption &&
                  infoProduct.idProduct === selectedProduct.idProduct && (
                    <DeleteProduct
                      setLoadingProducts={setLoadingProducts}
                      selectedProduct={selectedProduct}
                      delProductOption={delProductOption}
                      setDelProductOption={setDelProductOption}
                    />
                  )}
              </DivProduct>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchProduct>
  );
}
