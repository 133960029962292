import React, { useEffect, useState } from "react";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  BtnView,
  DivBtnEdit,
  DivBtnFilter,
  DivIdClient,
  DivClient,
  DivInfo,
  DivClientInfo,
  DivSearch,
  DivSearchClient,
  DivTableSearch,
  NameInput,
  NameLabel,
  IdInfo,
  SpanName,
  DivOrgLoading,
  DivCardClient,
  DivBtnSearch,
  FormatCPF,
  BtnCancel,
  FormatCPFText,
  SelectDoc,
  Options,
  DivOrgInput,
} from "./SearchClientStyle";
import UpdateClient from "../../Update/UpdateClient/UpdateClient";
import InfoClient from "../../Info/InfoClient/InfoClient";
import DeleteClient from "../../DeleteComponent/DeleteClient/DeleteClient";

import { Title } from "../../Status/StatusSell/StatusSellStyle";
import {
  Close,
  Edit,
  PersonRemove,
  Search,
  Visibility,
} from "@styled-icons/material";

import { ClipLoader } from "react-spinners";

export default function SearchClient(props) {
  const clientsInfo = props.clientsInfo;
  const [clientPopUp, setClientPopUp] = useState(false);
  const [selectDoc, setSelectDoc] = useState("cpf");
  const [delClientOption, setDelClientOption] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedClientView, setSelectedClientView] = useState();
  const [filterCPFClient, setFilterCPFClient] = useState();
  const [filterNameClient, setFilterNameClient] = useState("");
  const [filterInfoClient, setFilterInfoClient] = useState([]);

  const [clientView, setClientView] = useState(false);

  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState();
  const [loadingClients, setLoadingClients] = useState(false);
  const [dataClientUpdate, setDataClientUpdate] = useState([]);

  const createList = (dataClient) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoClient(dataClient);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // ! - usar para formatar dois nomes Ex. (henrique silva)
  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterClient = () => {
    const filterList = clientsInfo
      .filter((client) =>
        filterCPFClient.length > 0
          ? client.docClient.startsWith(filterCPFClient)
          : client
      )
      .filter((client) =>
        filterNameClient.length > 0
          ? client.clientName.includes(
              filterNameClient.toLocaleLowerCase()
            )
          : client
      );

    setFilterInfoClient(filterList);
  };

  useEffect(() => {
    createList(clientsInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsInfo]);

  useEffect(() => {
    setLoading(true);
    if (loadingClients) {
      props.getListClients();
    }
    setTimeout(() => {
      setLoadingClients(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingClients]);

  return (
    <DivSearchClient>
      <DivSearch>
        <Title>Consulta Cliente</Title>
        <DivBtnFilter show={props.disableFilter}>
          <DivOrgInput>
            <SelectDoc onChange={(e) => setSelectDoc(e.target.value)}>
              <Options value="cpf">CPF</Options>
              <Options value="cnpj">CNPJ</Options>
            </SelectDoc>

            {selectDoc === "cpf" ? (
              <FormatCPF
                value={filterCPFClient}
                format="###.###.###-##"
                allowEmptyFormatting
                mask="_"
                onValueChange={(values, sourceInfo) => {
                  setFilterCPFClient(values.value);
                }}
              />
            ) : (
              <FormatCPF
                value={filterCPFClient}
                format="##.###.###/####-##"
                allowEmptyFormatting
                mask="_"
                onValueChange={(values, sourceInfo) => {
                  setFilterCPFClient(values.value);
                }}
              />
            )}
          </DivOrgInput>
          <DivOrgInput>
            <NameLabel>Nome</NameLabel>
            <NameInput
              value={filterNameClient}
              onChange={(e) => setFilterNameClient(e.target.value)}
            />
          </DivOrgInput>
          <DivBtnSearch>
            <BtnSearch type="button" onClick={filterClient}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCPFClient("");
                setFilterNameClient("");
                setFilterInfoClient(clientsInfo);
              }}
            >
              <Close />
            </BtnCancel>
          </DivBtnSearch>
        </DivBtnFilter>
      </DivSearch>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoClient.map((infoClient, index) => {
            return (
              <DivClient key={infoClient.idClient}>
                <DivCardClient>
                  <DivInfo>
                    <DivIdClient>
                      <IdInfo>{index + 1}</IdInfo>
                    </DivIdClient>
                    <DivClientInfo>
                      <SpanName>
                        {parseName(infoClient.clientName, infoClient.lastName)}
                      </SpanName>
                      {infoClient.docClient.length > 11 ? (
                        <FormatCPFText
                          displayType="text"
                          value={infoClient.docClient}
                          format="##.###.###/####-##"
                          allowEmptyFormatting
                          mask="_"
                        />
                      ) : (
                        <FormatCPFText
                          displayType="text"
                          value={infoClient.docClient || "00000000000"}
                          format="###.###.###-##"
                          allowEmptyFormatting
                          mask="_"
                        />
                      )}
                    </DivClientInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setClientPopUp(!clientPopUp);
                        setDataClientUpdate(infoClient);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {clientPopUp &&
                      infoClient.idClient === dataClientUpdate.idClient && (
                        <UpdateClient
                          setLoadingClients={setLoadingClients}
                          dataClientUpdate={dataClientUpdate}
                          clientPopUp={clientPopUp}
                          setClientPopUp={setClientPopUp}
                        />
                      )}
                    {/* Cria um use state e passa o id para o state E ele passa para a função de delete */}
                    <BtnView
                      onClick={() => {
                        setClientView(!clientView);
                        setSelectedClientView(infoClient);
                        setDelClientOption(false);
                      }}
                    >
                      <Visibility />
                    </BtnView>
                    <BtnRemove
                      // type="button"
                      onClick={() => {
                        setDelClientOption(!delClientOption);
                        setSelectedClient(infoClient);
                        setClientView(false);
                      }}
                    >
                      <PersonRemove />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivCardClient>
                {clientView &&
                  infoClient.idClient === selectedClientView.idClient && (
                    <InfoClient
                      selectedClientView={selectedClientView}
                      clientView={clientView}
                      setClientView={setClientView}
                    />
                  )}
                {delClientOption &&
                  infoClient.idClient === selectedClient.idClient && (
                    <DeleteClient
                      setLoadingClients={setLoadingClients}
                      selectedClient={selectedClient}
                      delClientOption={delClientOption}
                      setDelClientOption={setDelClientOption}
                    />
                  )}
              </DivClient>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchClient>
  );
}
